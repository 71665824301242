import React, { useCallback, useEffect, useState } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import {
  useGetBankStatementByDocytId,
  useGetNextBankStatement,
  useApproveBankStatement,
  useBankStatementAttachmentUpload,
} from '@src/hooks/queries/bank_statements';
import { updateBankStatement } from '@src/requests/bank_statements';
import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';

import { Button, CancelModalButton } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal/modal';
import { ErrorNotification } from '@src/components/ui/notification';
import { CaretIcon } from '@src/components/utils/fa_icons';
import { ThumbsUpIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';
import QueryStatus from '@src/components/utils/query_status';

import DetailForm, { IBankStatementDetailsFormValues } from './details_form';
import Title from './details_modal_title';
import { useStartDateErrorModal } from './error_modals/start_date_error_modal';
import { useValidationErrorModal } from './error_modals/validation_error_modal';
import { useVerifyBankFeedModal } from './verify_bank_feed/verify_bank_feed_modal';

import styles from '../styles.module.scss';

const formId = 'bank_statement_detail_form';

type TOpenValue = {
  docytId: string,
}

interface IBankStatementDetailsModalProps extends IUseModalProps<TOpenValue> {
  businessId: TID,
  isOpen: boolean,
  isAdmin?: boolean,
  onCancel: () => void;
  onCloseModal?: () => void,
}

const BankStatementDetailsModal = ({
  businessId,
  isAdmin = true,
  isOpen,
  openValue,
  onCancel,
  onCloseModal,
}: IBankStatementDetailsModalProps): JSX.Element => {
  const { docytId } = openValue || {};
  const [verifyAction, setVerifyAction] = useState<boolean>(false);
  const [bankStatementData, setBankStatementData] = useState<IBankStatement | undefined>(undefined);
  const [enableNextStatementQuery, setEnableNextStatementQuery] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const startDateErrorModal = useStartDateErrorModal();
  const { openWithValue } = startDateErrorModal;
  const validationErrorModal = useValidationErrorModal();
  const { open: openValidationError } = validationErrorModal;

  const getBankStatementByDocytIdQuery = useGetBankStatementByDocytId(businessId, docytId, isOpen);
  const bankStatement = getBankStatementByDocytIdQuery.data?.bankStatement;

  const nextBankStatementQuery = useGetNextBankStatement(
    bankStatement?.id,
    enableNextStatementQuery,
  );
  const { refetch: refetchNextStatement } = nextBankStatementQuery;

  const approve = useApproveBankStatement(true);
  const { mutate: approveBankStatement } = approve;

  const uploadAttachment = useBankStatementAttachmentUpload();
  const { mutate: uploadBankStatementFile } = uploadAttachment;

  const verifyBankFeedModal = useVerifyBankFeedModal();

  const handleCloseModal = useCallback(() => {
    if (onCloseModal) onCloseModal();
    onCancel();
  }, [onCancel, onCloseModal]);

  const handleFileUpload = useCallback((file) => {
    if (bankStatement?.id && file ) {
      uploadBankStatementFile(
        {
          statementId: bankStatement.id,
          businessId,
          file
        }
      );
    }
  }, [bankStatement?.id, businessId, uploadBankStatementFile]);

  const handleCallNextStatement = useCallback(() => {
    setEnableNextStatementQuery(true);

    refetchNextStatement()
      .then((res) => {
        if (res.isError) {
          handleCloseModal();
          return;
        }

        setBankStatementData(res.data?.bankStatement);
        setEnableNextStatementQuery(false);
      });
  }, [handleCloseModal, refetchNextStatement]);

  const handleDone = useCallback(() => {
    if (verifyAction) {
      handleCloseModal();
      verifyBankFeedModal.open();
    } else {
      handleCallNextStatement();
    }
  }, [handleCallNextStatement, handleCloseModal, verifyAction, verifyBankFeedModal]);

  const handleSubmit = useCallback((value: IBankStatementDetailsFormValues) => {
    if (value?.bankStatement?.id === undefined) return;

    approveBankStatement({
      id: value.bankStatement?.id,
    }, {
      onSuccess: handleDone,
    });

    if (bankStatement?.id && !bankStatement.statementFileUrl)
    {
      handleFileUpload(value.file)
    }
  }, [approveBankStatement, bankStatement?.id, bankStatement?.statementFileUrl, handleDone, handleFileUpload]);

  const handleError = useCallback((errorMessage, rcPaymentAccountId, closingBalance, statement) => {
    if (errorMessage
      === window.Docyt.Common.Constants.Messages.BANK_STATEMENT_START_DATE_ERROR_MSG) {
      openWithValue({
        rcPaymentAccountId,
        closingBalance,
        bankStatement: statement,
      });
    } else if (errorMessage
      === window.Docyt.Common.Constants.Messages.BANK_STATEMENT_ARCHIVED_ERROR_MSG) {
      openValidationError();
    } else {
      setErrorMsg(errorMessage);
    }
  }, [openValidationError, openWithValue]);

  const handleChange = useCallback((value: IBankStatementDetailsFormValues) => {
    if (value?.bankStatement?.id === undefined) return;

    updateBankStatement({
      id:                             value.bankStatement?.id,
      businessId,
      reconciliationPaymentAccountId: value.reconciliationPaymentAccountId,
      bankStatement:                  {
        closingBalance: value.closingBalance,
        date:           value.date,
      },
    }).catch((error) => {
      if (error?.response?.data?.errors[0] === undefined) return;

      handleError(
        error?.response?.data?.errors[0],
        value.reconciliationPaymentAccountId,
        value.closingBalance,
        value.bankStatement,
      );
    });
  }, [businessId, handleError]);

  useEffect(() => {
    setBankStatementData(bankStatement);
  }, [bankStatement, isOpen]);

  useEffect(() => {
    setVerifyAction(false);
  }, [isOpen]);

  return (
    <>
      <MutationStatus mutation={ approve } successMessage="Approved successfully." />
      <QueryStatus query={ getBankStatementByDocytIdQuery } />
      { errorMsg && <ErrorNotification message={ errorMsg } title="Warning!" onHidden={ () => setErrorMsg(undefined) } /> }
      <startDateErrorModal.Component
        businessId={ businessId }
        { ...startDateErrorModal.props }
      />
      <validationErrorModal.Component
        { ...validationErrorModal.props }
        message={ window.Docyt.Common.Constants.Messages.BANK_STATEMENT_ARCHIVED_ERROR_MSG }
        title={ window.Docyt.Common.Constants.Messages.BANK_STATEMENT_ARCHIVED_ERROR_MSG_TITLE }
      />
      {
        bankStatementData && (
          <verifyBankFeedModal.Component
            { ...verifyBankFeedModal.props }
            businessId={ businessId }
            docytId={ bankStatementData.docytId }
          />
        )
      }
      <Modal
        showClose
        className={ styles['bank-statement-detail-modal'] }
        show={ isOpen }
        title={ (
          <Title
            bankStatement={ bankStatementData }
            businessId={ businessId }
            enableDeleteAction={ isAdmin }
            onDeleted={ handleCallNextStatement }
          />
        ) }
        onCancel={ handleCloseModal }
      >
        <Modal.Body>
          { isOpen && bankStatementData && (
            <DetailForm
              bankStatement={ bankStatementData }
              businessId={ businessId }
              formId={ formId }
              onChange={ handleChange }
              onSubmit={ handleSubmit }
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <CancelModalButton
            title="Back"
            onClick={ handleCloseModal }
          />
          {
            verifyAction && (
              <Button
                bsColor="blue"
                className={ styles['save-and-approve-btn'] }
                disabled={ !isAdmin || bankStatement?.state === 'verified' || bankStatement?.state === 'not_available' }
                form={ formId }
                type="submit"
              >
                <ThumbsUpIcon fontSize={ 18 } />
                <span className="m-l-10">Approve and Verify</span>
              </Button>
            )
          }
          <div className={ (!isAdmin || bankStatement?.state !== 'uploaded') ? styles['save-and-close-disabled-btn'] : styles['save-and-close-btn'] }>
            <Button
              bsColor="blue"
              disabled={ !isAdmin || bankStatement?.state !== 'uploaded' }
              form={ formId }
              type="submit"
              onClick={ () => setVerifyAction(false) }
            >
              Approve - Review Next
            </Button>
            <Button
              bsColor="blue"
              className={ styles['toggle-btn'] }
              disabled={ !isAdmin || bankStatement?.state !== 'uploaded' }
              onClick={ () => setVerifyAction((prev) => !prev) }
            >
              <CaretIcon
                fontSize={ 14 }
                variant={ verifyAction ? 'up' : 'down' }
              />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const MemoizedBankStatementDetailsModal = React.memo(BankStatementDetailsModal);
const useBankStatementDetailsModal = makeUseModal<
 typeof MemoizedBankStatementDetailsModal, TOpenValue>(MemoizedBankStatementDetailsModal);

export {
  IBankStatementDetailsModalProps,
  useBankStatementDetailsModal,
  BankStatementDetailsModal as default,
};
