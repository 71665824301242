import React from 'react';

import useUserFeatureFlag from '@src/hooks/use_user_feature_flag';
import { IBusiness } from '@src/types/businesses';
import { IClientBusiness } from '@src/types/clients';

import { useCreatePortfolioModal } from '../business_reports/custom_reports/modals/create_portfolio';
import { PlusIconCircle } from '../utils/fa_icons';

import styles from './styles.module.scss';

const AddNewPortfolio = ({ businesses }: {
  businesses: IBusiness[] | IClientBusiness[];
}) => {
  const CreatePortfolioModal = useCreatePortfolioModal();
  const isPortfolioFeatureEnabled = useUserFeatureFlag(window.Docyt.Common.Constants.ENABLE_CREATE_PORTFOLIO_OPTION, true);

  if (!isPortfolioFeatureEnabled) {
    return null;
  }

  return (
    <>
      <li className={ styles['border-top'] }>
        <a
          className="submenu-title"
          role="button"
          tabIndex={ 0 }
          onClick={ () => CreatePortfolioModal.open() }
        >
          <div className="m-l-18 m-t-8 m-b-8">
            <PlusIconCircle />
            <span className="m-l-5">Add New Portfolio</span>
          </div>
        </a>
      </li>
      <CreatePortfolioModal.Component
        { ...CreatePortfolioModal.props }
        businesses={ businesses }
      />
    </>
  );
};

export default AddNewPortfolio;
