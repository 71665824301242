import React, { useMemo } from 'react';

import { useGetPortfolio } from '@src/hooks/queries/portfolio_services/portfolio_services';
import { useLocalStorage } from '@src/hooks/storage';
import useUserFeatureFlag from '@src/hooks/use_user_feature_flag';
import { chartOfAccountsPath, multiEntityReportsPath, portfolioDashboardPath, portfolioDashboardsWidgetPath } from '@src/routes';
import { IBusiness } from '@src/types/businesses';
import { IClientBusiness } from '@src/types/clients';
import { TLaunchDarklyFeature } from '@src/types/launch_darkly';
import { IPortfolio } from '@src/types/portfolio';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import AddNewPortfolio from './add_new_portfolio';
import ServiceItem from '../header/module_header/module_header_item';
import ModuleHeaderMenu from '../header/module_header/module_header_menu';

interface IServiceMenuProps {
  launchDarklyFeature?: TLaunchDarklyFeature,
  businesses: IBusiness[] | IClientBusiness[],
  isClientView: boolean,
}

const PortfolioDropDownList = ({
  launchDarklyFeature,
  businesses,
  isClientView,
}: IServiceMenuProps) => {
  const portfolioResp = useGetPortfolio();
  const storedPortfolio = useLocalStorage('selectedPortfolio', {});
  const selectedPortfolio: any = storedPortfolio.storedValue;
  const selectedPortfolioId = selectedPortfolio?.id;

  const featureFlag = window.Docyt.Common.Constants.CHART_OF_ACCOUNTS_FLAG;
  const isCOAEnabled = useUserFeatureFlag(featureFlag);
  const isPortfolioFeatureEnabled = useUserFeatureFlag(window.Docyt.Common.Constants.ENABLE_CREATE_PORTFOLIO_OPTION, true);

  const portfolioList = useMemo(() => {
    if (portfolioResp?.data?.portfolios && portfolioResp.data.portfolios.length > 0) {
      return portfolioResp?.data?.portfolios.filter((item) => item.isClientBusinesses === (selectedPortfolio.isClientBusinesses || isClientView));
    }
    return [];
  }, [portfolioResp.data, isClientView, selectedPortfolio.isClientBusinesses]);

  const getSelectedPortfolio = () => {
    if (portfolioList.length > 0) {
      const selectedPortfolio = portfolioList.filter(
        (item) => item.id === Number(selectedPortfolioId),
      );
      if (selectedPortfolio.length) {
        return selectedPortfolio[0].name.toUpperCase();
      }
    }
    if (isPortfolioFeatureEnabled) {
      return 'Select Portfolio';
    }
    return window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_TITLE.toUpperCase();
  };

  const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement>, portfolio: IPortfolio) => {
    event.preventDefault();
    storedPortfolio.setValue(portfolio);
    backboneNavigateToUrl(portfolioDashboardsWidgetPath(portfolio.id));
  }

  return (
    <div data-global-feature-toggle={ launchDarklyFeature }>
      <ModuleHeaderMenu
        activePlaceholder={ !selectedPortfolioId }
        className="font-16 font-bold"
        listClassName="portfolio-dropdown-list"
        showSelect={ false }
        title={ getSelectedPortfolio() }
      >
        {!isPortfolioFeatureEnabled ? (
          <>
            <ServiceItem
              serviceUrl={ portfolioDashboardPath() }
              title={ window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.KPI_DASHBOARD }
            />
            <ServiceItem
              serviceUrl={ multiEntityReportsPath() }
              title={ window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.REPORTS }
            />
            {isCOAEnabled && (
              <ServiceItem
                serviceUrl={ chartOfAccountsPath() }
                title={ window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.CHART_OF_ACCOUNTS }
              />
            )}
          </>
        ) : (
          <>
            {portfolioList.map((portfolio) => {
              return (
                <ServiceItem
                  key={ portfolio.name }
                  serviceUrl=''
                  title={ portfolio.name }
                  onClickHandler={ (event) => handleOnClick(event, portfolio) }
                />
              );
            })}
          </>
        )}
        <AddNewPortfolio businesses={ businesses } />
      </ModuleHeaderMenu>
    </div>
  );
};

export default PortfolioDropDownList;
