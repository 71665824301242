import React from 'react';

import { IBusinessUser } from '@src/types/business_users';
import { IPortfolioDashboard } from '@src/types/portfolio';

import { TOption } from '@src/components/ui_v2/inputs';

import styles from './styles.module.scss';

interface IDashboardDetailsHeaderProps {
  dashboard: IPortfolioDashboard,
  onShareDone: (accessibleUsers: IBusinessUser[]) => void,
  options: TOption[],
  selectedOptions: TOption[],
  onChange: (value: TOption[]) => void,
}

const DashboardDetailsHeader = ({
  dashboard,
  onShareDone,
  options,
  selectedOptions,
  onChange,
}: IDashboardDetailsHeaderProps): JSX.Element => {
  
  return (
    <div className={ styles['details-header'] }>
      <div className={ styles['right-wrapper'] }>
      </div>
    </div>
  );
};

export default DashboardDetailsHeader;
