import React from 'react';

import { IGetBankFeedInformationResponse } from '@src/requests/bank_feed_transaction_service_documents';
import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { formatFullMonth } from '@src/utils/date_helpers';

import { useAddTransaction } from '@src/components/banking_accounts/transactions/add_transaction';
import { Button } from '@src/components/ui/buttons';
import DetailsPageTitle from '@src/components/ui/details_page_title';
import { PlusIcon } from '@src/components/utils/icomoon';

import { IBankFeedTransactionsValues } from '../schema';
import UploadView from '../upload_view';
import StatementInfo from './statement_info';
import TransactionList from './transactions_list';

import styles from '../../styles.module.scss';

interface IVerifyBankFeedFormProps {
  bankStatement?: IBankStatement,
  bankFeedInformation?: IGetBankFeedInformationResponse
  businessId: TID,
  isAdmin: boolean,
  isFileUploading: boolean,
  handleFileUpload: (file: File) => void,
  onChangeBankFeedTransactions: (bankFeedBalance: number, unselectedCount: number) => void,
}

const VerifyBankFeedForm = ({
  bankStatement,
  bankFeedInformation,
  businessId,
  isAdmin,
  isFileUploading,
  handleFileUpload,
  onChangeBankFeedTransactions,
}: IVerifyBankFeedFormProps): JSX.Element => {
  const addTransaction = useAddTransaction();

  return (
    <>
      <addTransaction.Component
        endDate={ bankStatement?.closingDate }
        reconciliationPaymentAccount={ bankStatement?.reconciliationPaymentAccount }
        startDate={ bankStatement?.startingDate }
        { ...addTransaction.props }
      />
      <div className="display-flex width-100-percent">
        {
          bankStatement && (
            <>
              <div className={ styles['verify-bank-feed-file-view'] }>
                <UploadView
                  bankStatement={ bankStatement }
                  error=""
                  isFileUploading={ isFileUploading }
                  onUploaded={ handleFileUpload }
                />
              </div>
              <div className={ styles['verify-bank-feed-form-container'] }>
                <DetailsPageTitle
                  isSmallTitle
                  breadcrumbs={ {
                    title: bankStatement?.reconciliationPaymentAccount?.name,
                  } }
                  title={ <span className="font-bold">{ formatFullMonth(bankStatement.date) }</span> }
                >
                  {
                    isAdmin && (
                      <Button
                        bsStyle="link"
                        className="font-bold display-flex align-items-center"
                        onClick={ addTransaction.open }
                      >
                        <PlusIcon mr={ 10 } variant="o" />
                        Add Missing Transaction
                      </Button>
                    )
                  }
                </DetailsPageTitle>
                <div className={ styles['statement-detail-form'] }>
                  <StatementInfo bankStatement={ bankStatement } />
                  <TransactionList
                    bankFeedInformation={ bankFeedInformation }
                    bankStatement={ bankStatement }
                    businessId={ businessId }
                    onChangeBankFeedTransactions={ onChangeBankFeedTransactions }
                  />
                </div>
              </div>
            </>
          )
        }
      </div>
    </>
  );
};

export {
  IVerifyBankFeedFormProps,
  IBankFeedTransactionsValues,
  VerifyBankFeedForm as default,
};
